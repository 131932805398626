<template>
  <div class="login-content d-flex flex-column p-10 animate__animated animate__fadeIn">
    <!--begin::Signin-->
    <div class="login-form login-signin">
      <!--begin::Logo-->
      <div v-if="$vuetify.breakpoint.mobile" class="text-center mb-10">
        <a href="#" class="flex-column-auto">
          <img src="/media/logos/logo-with-name-black-vertical.png" width="70%" />
        </a>
      </div>

      <div class="pb-10 text-center">
        <h3 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Faça seu Login</h3>
        <div class="text-muted font-weight-bold font-size-h4">
          Não possuí cadastro?
          <router-link custom class="text-primary font-weight-bolder" :to="{ name: 'register' }">
            Cadastre-se!
          </router-link>
        </div>
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div
          v-if="Object.keys(errors).length"
          role="alert"
          :class="{ show: Object.keys(errors).length }"
          class="alert fade alert-danger animate__animated animate__bounceIn"
        >
          <div class="alert-text d-flex align-items-center">
            <i class="flaticon-warning text-light mr-2"></i>
            {{ errors }}
          </div>
        </div>

        <b-form-group label="Seu email" label-class="font-size-h6 font-weight-bolder text-dark" label-for="email">
          <b-form-input
            id="email"
            v-model="$v.form.email.$model"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            :state="validateState('email')"
            aria-describedby="input-1-live-feedback"
          />

          <b-form-invalid-feedback id="input-1-live-feedback">
            Email is required and a valid email address.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label-for="password">
          <template slot="label">
            <div class="d-flex justify-content-between mt-n5">
              <label class="font-size-h6 font-weight-bolder text-dark pt-5">Sua Senha</label>
              <a
                href="/metronic/demo2/custom/pages/login/login-3/forgot.html"
                class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
              >
                Esqueceu sua senha ?
              </a>
            </div>
          </template>
          <b-form-input
            id="password"
            v-model="$v.form.password.$model"
            class="form-control form-control-solid h-auto py-5 px-6 rounded"
            type="password"
            :state="validateState('password')"
            aria-describedby="input-2-live-feedback"
          />

          <b-form-invalid-feedback id="input-2-live-feedback">Password is required.</b-form-invalid-feedback>
        </b-form-group>

        <!--begin::Action-->
        <div class="form-group d-flex flex-wrap justify-content-center align-items-center">
          <div class="pb-lg-0 pb-5">
            <button
              ref="kt_login_signin_submit"
              type="submit"
              class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
            >
              Entrar
            </button>
            <button type="button" class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg">
              <span class="svg-icon svg-icon-md">
                <!--begin::Svg Icon | path:/metronic/theme/html/demo2/dist/assets/media/svg/social-icons/google.svg-->
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    d="M19.9895 10.1871C19.9895 9.36767 19.9214 8.76973 19.7742 8.14966H10.1992V11.848H15.8195C15.7062 12.7671 15.0943 14.1512 13.7346 15.0813L13.7155 15.2051L16.7429 17.4969L16.9527 17.5174C18.879 15.7789 19.9895 13.221 19.9895 10.1871Z"
                    fill="#4285F4"
                  ></path>
                  <path
                    d="M10.1993 19.9313C12.9527 19.9313 15.2643 19.0454 16.9527 17.5174L13.7346 15.0813C12.8734 15.6682 11.7176 16.0779 10.1993 16.0779C7.50243 16.0779 5.21352 14.3395 4.39759 11.9366L4.27799 11.9466L1.13003 14.3273L1.08887 14.4391C2.76588 17.6945 6.21061 19.9313 10.1993 19.9313Z"
                    fill="#34A853"
                  ></path>
                  <path
                    d="M4.39748 11.9366C4.18219 11.3166 4.05759 10.6521 4.05759 9.96565C4.05759 9.27909 4.18219 8.61473 4.38615 7.99466L4.38045 7.8626L1.19304 5.44366L1.08875 5.49214C0.397576 6.84305 0.000976562 8.36008 0.000976562 9.96565C0.000976562 11.5712 0.397576 13.0882 1.08875 14.4391L4.39748 11.9366Z"
                    fill="#FBBC05"
                  ></path>
                  <path
                    d="M10.1993 3.85336C12.1142 3.85336 13.406 4.66168 14.1425 5.33717L17.0207 2.59107C15.253 0.985496 12.9527 0 10.1993 0C6.2106 0 2.76588 2.23672 1.08887 5.49214L4.38626 7.99466C5.21352 5.59183 7.50242 3.85336 10.1993 3.85336Z"
                    fill="#EB4335"
                  ></path>
                </svg>
                <!--end::Svg Icon-->
              </span>
              Sign in with Google
            </button>
          </div>
        </div>

        <!--begin::Content header-->

        <!--end::Content header-->

        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from 'vuex';
import { LOGIN, LOGOUT } from '@/core/services/store/modules/auth';
import { validationMixin } from 'vuelidate';
import { minLength, required, email } from 'vuelidate/lib/validators';

export default {
  name: 'Login',
  mixins: [validationMixin],
  data() {
    return {
      // Remove this dummy login info
      form: {
        email: '',
        password: ''
      }
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors
    })
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  created() {
    this.$store.commit('RESET_ERROR');
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        email: null,
        password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async onSubmit() {
      // clear existing errors
      this.$store.dispatch(LOGOUT);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const email = this.$v.form.email.$model;
      const password = this.$v.form.password.$model;

      // set spinner to submit button
      const submitButton = this.$refs['kt_login_signin_submit'];
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');
      // dummy delay
      // send login request

      this.$recaptcha('login').then(async (recaptcha) => {
        await this.$store
          .dispatch(LOGIN, { email, password, recaptcha })
          // go to which page after successfully login
          .then(() => {
            this.$router.push(this.$route.query.redirect || { name: 'dashboard' });
          })
          .catch(() => {
            submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
          });
      });
    }
  }
};
</script>
